// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

$primary: #990000;
$white: #fff;
$black: #111;
$grey: #333333;
$mediumGrey: #666;
$border: #d4d4d4;
$error: #dd0000;
$formHeight: 45px;
$borderRadius: 8px;
$inputBorder: #e1e6e9;
$lightGray: #bbb;

// export
:export {
  primary: $primary;
}

#root~iframe {
  display: none !important;
}

// #root > div:first-child {
// display: none !important;
// }

body {
  letter-spacing: 0.3px;
  // add the font-family to be used(most often) in the project here
  // font-family: "Montserrat", sans-serif;
  font-family: "Nunito", sans-serif;
  color: $black;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: "Poppins", sans-serif;
  font-family: "Nunito", sans-serif;
}

a {
  text-decoration: none;

  &:hover {
    color: $primary;
  }
}

p {
  font-size: 14px;
}

// extraClasses
.fs-14 {
  font-size: 14px !important;
}

.text-primary {
  color: $primary;
}

.spinnerInTable {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.brandLogo {
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  border: 1px solid $border;
  overflow: hidden;
  margin-right: 10px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

img {
  font-size: 10px;
}

.cursorPointer {
  cursor: pointer;
}

// header
.projectHeader {
  background-color: $white;
  box-shadow: 0px 1px 12px -5px rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 60px;

  .container-fluid {
    padding-inline: 15px;
  }

  .navbar-brand {
    padding-block: 0;
    cursor: pointer;
    margin: 0;
    position: absolute;
    top: 10px;
    left: 15px;

    img {
      height: 42px;
    }
  }

  .navbar-nav {
    width: 100%;
    display: flex;
    justify-content: center;

    .nav-item {
      .nav-link {
        color: $mediumGrey;
        font-size: 15px;
        margin-inline: 5px;
        position: relative;
        cursor: pointer;
        height: 40px;
        padding: 0 5px;
        line-height: 41px;

        &::after {
          background-color: initial;
          bottom: -11px;
          content: "";
          height: 2px;
          left: 0;
          position: absolute;
          right: 0;
          transition: 0.2s ease-in;
          width: 100%;
        }

        &.active {
          color: $primary;

          &::after {
            background-color: $primary;
          }
        }
      }
    }
  }

  .logoutBtn {
    color: $mediumGrey;
    margin-left: auto;
    font-weight: 400;

    span {
      display: none;
    }
  }
}

.breadcrumb {
  margin-bottom: 0;

  .breadcrumb-item {
    font-size: 15px;
    color: #6c757d !important;
    font-weight: 500 !important;

    &.active {
      color: #2c2c2c !important;
    }

    &+.breadcrumb-item::before {
      content: ">";
    }
  }
}

// Header DropDown
// .dropdown {
//   .userkey {
//     padding: 5px;
//     color: #747474;
//     margin-left: 10px;
//     font-weight: 600;
//     font-size: 15px;
//     border-radius: 10px;
//     background: #f2f2f2;
//     border: 1px solid $border;
//   }
//   .dropdown-toggle {
//     display: flex !important;
//     align-items: center;

//     .userid {
//       display: inline-block;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       text-transform: capitalize;
//       white-space: nowrap;
//       max-width: 15ch;
//     }
//   }

//   .dropdown-menu {
//     top: 15px !important;
//     overflow: hidden;
//     padding-block: 0 !important;
//     border: 1px solid #f1f1f1;
//     box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
//     .dropdown-item {
//       padding-block: 10px !important;
//       font-size: 14px !important;
//       outline: none !important;

//       &:not(:last-child) {
//         border-bottom: 1px solid #f1f1f1 !important;
//       }

//       &:hover {
//         background-color: #f1f1f1 !important;
//       }
//     }
//   }
// }

// Sidebar
.sidebarWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  // padding: 30px;
  // border-radius: 20px;
  min-height: calc(100vh - 110px);
  z-index: 9;
  visibility: hidden;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba($color: $black, $alpha: 0.3);
    z-index: -1;
    display: none;
  }

  .sidebarInner {
    height: 100%;
    padding: 30px;
    overflow-y: auto;
    border-radius: 20px;
    background: #f6f6f6;
    transform: translateX(-500px);
    transition: 0.3s ease-in-out;

    .sidebar_block {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px dashed $border;
    }

    // user profile
    .sidebar_userProfile {
      img {
        width: 50px;
        height: 50px;
        border-radius: 13px;
      }

      // title
      h2 {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
      }

      p {
        font-size: 14px;
        color: $mediumGrey;
      }

      // user info
      .sidebar_userInfo {
        gap: 20px;
        display: flex;
        flex-wrap: wrap;
        margin: 25px 0 0;
        align-items: center;
        justify-content: space-between;

        li {
          font-size: 15px;
          line-height: 1;
          font-weight: 500;
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            border-radius: 0;
            margin-right: 10px;
          }
        }
      }
    }

    // candidate
    .sidebar_candidates {

      // title
      .sidebar_candidatesTitle {
        gap: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        h2 {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 0;
        }

        .form-group {
          display: flex;
          align-items: center;

          label {
            margin-bottom: 0 !important;
          }

          .form-select {
            cursor: pointer;
            height: auto;
            padding-block: 0;
            background-color: transparent;
            border: none;
            font-weight: 500;
            line-height: 1;
          }
        }
      }

      // search
      .sidebar_candidatesSearch {
        position: relative;
        margin-top: 20px;

        input {
          color: $mediumGrey;
          border: none;
          padding-left: 40px;
          border-radius: 12px;
          background-color: #e9e9e9;
        }

        .fa-search {
          position: absolute;
          top: 50%;
          left: 15px;
          font-size: 15px;
          color: $mediumGrey;
          transform: translateY(-50%);
        }
      }

      // add another candidate
      .sidebar_candidateAdd {
        font-size: 14px;
        font-weight: 500;
        color: $mediumGrey;
        text-align: center;
        padding: 15px;
        cursor: pointer;
        margin-block: 20px 12px;
        border-radius: 10px;
        border: 1px dashed $border;

        i {
          margin-right: 10px;
        }
      }

      // cards
      .sidebar_candidateCards {
        >li {
          cursor: pointer;
          padding: 15px 20px;
          border-radius: 12px;
          border: 1px solid lighten($border, 10);

          &:first-child,
          &:hover {
            background-color: #ffffffa6;
          }

          &:not(:last-child) {
            margin-bottom: 15px;
          }

          .card-title {
            font-size: 16px;
            font-weight: 600;
            line-height: 1;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
          }

          span {
            color: #747474;
            font-size: 14px;
            margin-bottom: 0;
          }

          .sidebar_candidateCards_list {
            margin: 10px 0 0;
            display: flex;
            align-items: center;

            li {
              flex: 1;
              height: 4px;
              margin-right: 5px;
              background-color: #cfcfcf;

              &.fill {
                background-color: $black;
              }
            }
          }
        }
      }
    }
  }

  &.show {
    visibility: visible;

    .sidebarInner {
      transform: translateX(0);
    }

    &::before {
      display: block;
    }
  }
}

// footer
.projectFooter {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: $mediumGrey;
  width: 100%;

  a {
    color: $mediumGrey;
    text-decoration: none;
  }
}

// button
.btn {
  line-height: 1;
  border-radius: 8px;
  transform: scale(1);
  transition: all .3s;
  font-weight: 600;

  &:focus,
  &:active:focus {
    box-shadow: none !important;
  }

  &:active {
    transform: scale(1.03);
    transition: all .3s;
  }

  &.btn-submit {
    width: calc(min(100% - 1rem, 280px));
    display: block;
    margin: 30px auto 0;
    height: 44px;
  }

  &-primary {
    background-color: $primary;
    border-color: $primary;

    &:hover,
    &:focus,
    &:active:focus {
      border-color: rgba($color: $primary, $alpha: 0.9);
      background-color: rgba($color: $primary, $alpha: 0.9);
    }

    &:disabled {
      border-color: rgba($color: $primary, $alpha: 0.8);
      background-color: rgba($color: $primary, $alpha: 0.8);
    }
  }

  &-link {
    padding: 0 10px;
    text-decoration: none;
    color: $primary;

    &:hover {
      color: $primary;
    }
  }

  &-outline-primary {
    color: $primary !important;
    border-color: $primary !important;
    background-color: transparent !important;
  }

  &-outline-secondary {
    // color: $lightBlack !important;
    border-color: $border !important;
    background-color: transparent !important;
  }

  &-outline-danger {
    &.btn_Reset {
      width: 45px;
      height: 45px;
      padding: 0;

      i {
        font-size: 16px;
      }

      &:hover {
        color: $white;
        background-color: $error;
      }
    }
  }
}

// filter
.filterWrapper {
  padding: 16px 15px 0;
  border-radius: 10px;
  // display: flex;
  background: $white;
  margin-bottom: 30px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  // flex-wrap: wrap;

  .filterIcon {
    font-size: 18px;
    color: $lightGray;
    align-self: center;
    margin-right: 20px;
  }

  // .mb-3 {
  //   margin-right: 12px;

  //   &:last-child {
  //     margin-right: 0;
  //   }
  // }

  .searchTable {
    // max-width: 180px;
    // min-width: 180px;

    .btn {
      padding: 0 10px 0 5px;
      color: $mediumGrey;
      font-size: 14px;

      i {
        vertical-align: 1px;
      }
    }
  }

  .btn {
    padding: 0 14px;
    height: 45px;
    border-radius: 8px;
    line-height: 45px;
    box-shadow: none !important;
    background-color: $white;
  }

  #clearFilter {
    &:hover {
      background-color: #ffc107;
    }
  }

  .input-group-append .btn {
    border-color: $inputBorder !important;
    color: $lightGray !important;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: $white !important;

    &:active {
      border-color: rgba($primary, 0.85) !important;
      color: $primary !important;
    }
  }

  // .select__control,
  // .react-select-container {
  //   min-width: 160px;
  // }

  .select__placeholder {
    top: 48% !important;
    color: #585c5f;
  }

  // .mb-3:has(.searchTable) {
  //   margin-left: auto;
  // }
}

// form
label {
  font-size: 14px;
  margin-bottom: 5px !important;
  color: $mediumGrey;
  letter-spacing: 0.3px;
}

input {

  //remove number arrows
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  // auto login field
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
}

/* ===== Input Group ==== */
.input-group {
  overflow: hidden;
  border-radius: $borderRadius;
  border: 1px solid $border;
  background-color: transparent;

  .form-control,
  .btn {
    height: 43px;
  }

  .input-group-text,
  .input-group-prepend,
  .input-group-append {
    color: $grey;
    border: none;
    font-size: 14px;
    border-radius: 0;
    background-color: transparent;

    &.phoneCode {
      padding: 0;
    }

    input,
    select {
      border: none !important;
    }

    select {
      max-width: 250px;
    }

    i {
      font-size: 14px !important;
    }
  }

  .input-group-text {
    background-color: #f1f1f1;

    &.inputSelect {
      padding: 0 !important;
    }
  }

  input {
    border-radius: 0;
    background-color: transparent;
    border: none !important;
  }

  &:focus-within {
    border-color: $black;
  }
}

.form-control,
.form-control-file,
.form-select {
  color: $black !important;
  height: $formHeight;
  font-size: 14px;
  border-radius: $borderRadius;
  border-color: $border !important;
  transition: all .3s;
  font-weight: 500;

  &:focus {
    box-shadow: none !important;
    border-color: $black !important;
    transition: all .3s;
  }

  &:disabled {
    background-color: #f6f6f6 !important;
    cursor: not-allowed;
  }
}

.form-select {
  padding-right: 32px;
}

textarea {
  &.form-control {
    height: auto;
  }
}

.form-check {
  .form-check-input {
    &:checked {
      background-color: #198754;
      border-color: #198754;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

// error
.form-error {
  font-size: 13px;
  color: $error;
  margin-top: 4px;
}

// react select
.react-select {
  .css-1s2u09g-control {
    border-color: $border;
    min-height: 45px;

    &:hover {
      border-color: $border;
    }
  }

  .css-1pahdxg-control {
    min-height: 45px;
    border-color: $primary !important;
    box-shadow: none;
  }
}

// pagination
.tableCount {
  font-size: 14px;
  color: $mediumGrey;

  span {
    font-weight: 700;
    color: $black;
  }
}

.pagination {
  align-items: center;
  margin: 0;

  li {
    a {
      font-size: 14px;
      color: $mediumGrey !important;
      width: 26px !important;
      height: 26px !important;
      text-align: center;
      display: inline-block;
      border-radius: 5px;
      line-height: 26px;
      margin: 0 2px;
      line-height: 27px;
    }

    &.selected {
      a {
        background-color: $primary;
        color: $white !important;
      }
    }

    &:first-child,
    &:last-child {
      a {
        font-size: 16px;
      }
    }

    &.disabled {
      pointer-events: none;
    }
  }
}

// login
.authWrapper {
  position: relative;
  top: 0;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: -8px;

  background-color: #f8f8f8 !important;

  .loginWrap {
    width: calc(min(100% - 2rem, 450px));
    min-height: calc(100vh - 50px);
    padding: 30px 0;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;

    .companyLogo {
      margin-inline: auto;
      display: block;
      max-width: 200px;
      margin-top: -65px;
    }

    h2 {
      text-align: center;
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 26px;
      text-transform: uppercase;
    }

    form {
      background-color: $white;
      padding: 40px 25px;
      border: 1px solid hsla(0, 0%, 89.4%, 0.5);
      border-radius: $borderRadius;
      margin-top: 25px;
      width: 100%;
    }
  }

  .forgotPassword {
    color: $mediumGrey;
    font-size: 14px;

    &:hover {
      color: $primary;
    }
  }
}

// innerwrapper
.mainWrapper {
  width: 100%;
  min-height: 100vh;
  margin-bottom: -8px;
  background: #fbfbfb;

  .contentWrap {
    min-height: calc(100vh - 51px);
    padding-top: 60px;
  }
}

// main content
.main_content_wrapper {
  background-color: $white;
  padding: 15px;
  border-radius: $borderRadius;
}

// card
.card {
  background-color: $white;
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
  border-radius: $borderRadius;
  overflow: hidden;

  .card-header,
  .card-footer {
    background-color: transparent;
    border-color: rgba($color: $mediumGrey, $alpha: 0.2);
  }

  .card-header {
    padding: 12px 15px;
    font-size: 16px;
    font-weight: 500;
  }
}

// modal
.modal {
  .closeBtn {
    color: #bbb !important;
    border: 1px solid #bbb !important;
    background: $white !important;
  }

  .modal-header {
    padding: 13px 15px 11px;
    border-bottom-style: dashed;
  }

  .btn-close {
    padding: 5px;
    font-size: 14px;
    margin-right: 2px;
    box-shadow: none !important;
  }

  .modal-title {
    font-weight: 600;
    font-size: 20px;
    color: $black;
    margin-bottom: -2px;
  }

  .modal-footer {
    padding: 10px 15px;
    border-top-style: dashed;

    button {
      height: 38px;
      padding: 0 15px;
      margin-block: 0;
      line-height: 36px;
      font-size: 14px;
    }

    .btn-primary {
      background-color: $primary !important;
      color: $white !important;
      margin-left: 10px !important;
    }
  }

  .carousel-item {
    border-radius: 10px;
    overflow: hidden;

    img {
      height: 230px;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
      transition: all .5s;
  
      &:hover {
        transform: scale(1.12);
        transition: all .5s;
      }
    }
  }
}

// full page loader
.fullPgLoaderWrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  z-index: 9999;
  opacity: 0.95;
  background: $white;

  .loaderWrap {
    width: 250px;
    height: 200px;
    text-align: center;

    div {
      justify-content: center;
    }

    p {
      font-size: 16px;
      margin-top: 10px;
      color: $mediumGrey;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

// owner property

//  Custom Image Upload Boxes Gallery
.custom-file-upload-box-gallery {
  display: flex;
  overflow-x: auto;

  .custom-file-upload,
  .custom-file-upload-box {
    position: relative;
    width: 60px;
    min-width: 60px;
    height: 60px;
    line-height: 50px;
    font-size: 14px;
    padding: 4px;
    text-align: center;
    border: 1px dashed $border;
    border-radius: $borderRadius;
    margin-block: 10px !important;
    background-color: $white;
    cursor: pointer;
  }

  .custom-file-upload-box {
    padding: 0;
    margin: 10px 0 10px 10px;
    border: 1px solid $border;

    img {
      height: 100%;
      width: 100%;
      border-radius: $borderRadius;
      object-fit: cover;
    }

    .btn {
      position: absolute;
      font-size: 12px;
      z-index: 3;
      color: $error !important;
      border-radius: 50px;
      background-color: $white !important;
      height: 18px;
      width: 18px;
      padding: 0px 0 0;
      border: 0 !important;
      top: -5px;
      right: -5px;
      box-shadow: 0px 0px 3px 0px rgba($black, 0.4) !important;
      text-align: center;
    }
  }

  // Replace Img
  &.replace-input,
  &.coverImg {
    .custom-file-upload {
      margin-bottom: 10px;

      &.profile {
        min-width: 200px;
        max-width: 200px;
        height: 200px;
        margin-bottom: 0;
        cursor: default;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $borderRadius;
      }


    }

    .custom-file-upload~label,
    .makeCoverBtn {
      color: $primary;
      text-align: center;
      font-size: 14px;
      display: block;
      cursor: pointer;
    }
  }

  &.coverImg {
    * {
      cursor: initial;
    }
  }


}

// shop info card
.shopInfoWrap {
  border: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 24px;
  transition: all .3s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    transition: all .3s;
  }

  .carousel-item {
    border-radius: 10px;
    overflow: hidden;

    img {
      height: 230px;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
      transition: all .5s;
  
      &:hover {
        transform: scale(1.12);
        transition: all .5s;
      }
    }
  }

  .shopCode {
    position: absolute;
    bottom: 8px;
    right: 8px;
    z-index: 10;
    background: $white;
    color: $black;
    padding: 0 6px;
    height: 24px;
    line-height: 25px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }

  .card-body {
    padding: 18px 0 0;
  }

  h5 {
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 700;
    color: $black;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 20px;
    max-height: 20px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  h6 {
    font-size: 14px;
    margin-bottom: 15px;
    color: $black;
  }

  p {
    margin-bottom: 7px;
    color: $mediumGrey;

    i {
      margin-right: 2px;
      font-size: 13px;
    }
  }

  .card-footer {
    margin-top: 8px;
    padding: 12px 0 2px;
    display: flex;
    border-top-style: dashed;
    justify-content: space-between;
  }
}

.viewBids,
.btnOutline {
  height: 29px;
  padding: 0 10px;
  border-radius: 6px;
  background-color: $primary !important;
  border: 0;
  color: $white;
  line-height: 29px;
  font-weight: 600;
  font-size: 14px;
}

.btnOutline {
  background-color: $white !important;
  color: $primary;

  &:hover {
    background-color: rgba($primary, 0.2) !important;
    color: $primary !important;
  }
}

.actionBtn {
  width: 29px;
  height: 29px;
  border-radius: 6px;
  background-color: $white;
  color: $black !important;
  border: 0;
  padding: 0 2px;
  line-height: 31px;
  font-size: 14px;

  &:hover {
    background-color: rgba($primary, 0.2) !important;
  }

  &:not(:first-of-type) {
    margin-left: 10px;
  }
}

.paginationWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 25px;
}

.pgTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 40px 30px;

  h2 {
    font-size: 24px;
    color: $black;
    font-weight: 700;
    margin-bottom: 0;
  }
}

.addBtn {
  background-color: $primary;
  color: $white;
  border: 0;
  padding: 0 15px;
  height: 41px;
  line-height: 42px;
  font-weight: 600;
  font-size: 16px;

  &:hover {
    background-color: #bc0026 !important;
    border-color: #bc0026 !important;
    transition: all .3s;
  }

  &:disabled {
    background-color: rgba(#bc0026, 0.6);
  }
}

.prevBtn {
  background-color: $white;
  color: $primary;
  height: 41px;
  line-height: 41px;
  border: 1px solid $primary;
  padding: 0 15px;
  font-weight: 600;
  font-size: 16px;

  &:hover {
    background-color: $white !important;
    border-color: $primary !important;
    color: $primary !important;
    transition: all .3s;
  }
}

/* Styles for the filter wrapper */
// .filterWrapper {
//   display: flex;
//   align-items: center;
//   padding: 15px;
//   background-color: #f9fafc;
// }

// .filterWrapper .filterIcon {
//   font-size: 24px;
//   margin-right: 20px;
//   color: #999;
// }

// .filterWrapper .filterForm {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 20px;
// }

// .filterWrapper .formGroup {
//   display: flex;
//   flex-direction: column;
// }

// .filterWrapper .formGroup label {
//   font-weight: bold;
//   margin-bottom: 5px;
//   color: #333;
// }

// .filterWrapper .formGroup select,
// .filterWrapper .formGroup input {
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
// }

/* Styles for the search box and buttons */
.searchBoxContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.searchWrap {
  display: flex;
  align-items: center;
}

.searchWrap input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
}

.searchWrap .input-group-text {
  background-color: #fff;
  border: 1px solid #ccc;
  border-left: 0;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.searchWrap .input-group-text .fa-search {
  color: #999;
}

.searchWrap .spinner-border {
  width: 1rem;
  height: 1rem;
  border-width: 0.1em;
}

.btn_Reset {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  color: #333;
}

/* Styles for the property section */
.ownerProperty {
  .spinner-border {
    border-width: 3px;
  }
}

.sectionTitle {
  font-size: 22px;
  color: $black;
  margin-block: 10px 30px;
  position: relative;
  font-weight: 600;

  &::after {
    content: ' ';
    border-bottom: 3px solid $primary ;
    width: 40px;
    position: absolute;
    bottom: -7px;
    left: 0;
  }
}

/*Shop page styling end */

/* view modal styling */
.custom-modal-body {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;

  .form-group {
    margin-bottom: 15px;

    label {
      font-weight: bold;
      color: #333;
      display: block;
      margin-bottom: 5px;
    }

    input,
    select {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      transition: border-color 0.3s;

      &:focus {
        border-color: #007bff;
      }
    }
  }

  .carousel-component {
    margin-bottom: 20px;
  }
}

/* view modal styling end */

/*Dashboard Design*/
.dashboardCard {
  background-color: $white;
  padding: 15px;
  border-radius: $borderRadius;
  margin-bottom: 20px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 1.95px 1.95px 2.6px;
  flex: 1;

  p {
    margin-bottom: 5px;
    font-size: 14px;
    color: $black;
  }

  h3 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .fa-rupee-sign {
    font-size: 23px;
    vertical-align: 1px;
    margin-right: 3px;
  }

  .dashboardCardIcon {
    width: 60px;
    height: 70px;
    border-radius: $borderRadius;
    display: flex;
    align-items: center;
    margin-left: 30px;

    img {
      height: 40px;
      margin-left: -18px;
    }
  }
}

.bgOrange {
  background-color: #fef0df;
}

.bgGreen {
  background-color: #d8ffe6;
}

.colorOrange {
  color: #f8a33d;
}

.colorGreen {
  color: #36cc66;
}

.tooltip-inner {
  font-size: 14px;
  font-family: "Nunito", sans-serif;
}

.mobileFilter {
  display: none;
}

.swal2-title {
  font-size: 20px;
  color: $black;
  font-weight: 600;
}

.swal2-html-container {
  font-size: 16px !important;
}

button.swal2-styled {
  box-shadow: none !important;
  height: 40px;
  padding: 0 15px;
  border-radius: $borderRadius !important;
  font-weight: 600;
  transform: scale(1);
  transition: all .3s;

  &:active {
    transform: scale(1.03);
    transition: all .3s;
  }
}

.swal2-cancel {
  color: #bbb !important;
  border: 1px solid #bbb !important;
  background-color: $white !important;
  background-image: none !important;
}

.table {
  margin-bottom: 0;
  
  thead tr th {
    font-size: 14px;
    color: $black;
    background: #f5f5f5;
    vertical-align: middle;
    padding: 15px 10px 13px;
    border-color: #e8ebee;
  }

  tbody {
    tr {
      th, td, .btn {
        font-size: 14px;
        vertical-align: middle;
        color: $mediumGrey;
      }

      td, th {
        font-weight: 400;
        border-color: #e8ebee;
        padding: 15px 10px 14px;
        line-height: 1.4;

        .fa-rupee-sign {
          margin-right: 2px;
          font-size: 13px;
          vertical-align: 0;
          color: #808080;
        }
      }

      &:last-child {
        td, th {
          border-bottom: 0;
        }
      }

      .tableActionBtn {
        height: 32px;
        padding: 0 12px;
        border-radius: $borderRadius;
        line-height: 30px;
      }

      .btn-outline-success {
        color: #198754;
        border-color: #198754;

        &:hover, &:active {
          color: #fff;
          background-color: #198754;
          border-color: #198754;
        }
      }
      
      .btn-outline-success {
        color: #198754;
        border-color: #198754;

        &:hover, &:active {
          color: #fff;
          background-color: #198754;
          border-color: #198754;
        }
      }

      .btn-outline-danger {
        color: #dc3545;
        border-color: #dc3545;

        &:hover, &:active {
          color: #fff;
          background-color: #dc3545;
          border-color: #dc3545;
        }
      }
    }

    .actionBtn {
      color: $mediumGrey !important;

      &:hover {
        color: $black !important;
      }
    }

    .addBtn {
      color: $white !important;
      width: 29px;
      height: 29px;
      border-radius: 6px;
      padding: 0 2px;
      line-height: 30px;
    }
  }
}

.table-responsive {
  border-radius: $borderRadius;
  // border: 1px solid #e8ebee;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  &:not(:has(.checkBoxTable)) {
    max-height: 580px;
  }
}

.select__control {
  border-color: $border !important;
  min-height: $formHeight !important;
  box-shadow: none !important;
  border-radius: $borderRadius !important;
  font-size: 14px;
  font-weight: 500;

  .select__placeholder {
    position: unset !important;
    transform: unset !important;
  }

  .select__value-container {
    padding: 6px 4px 6px 10px;
    font-weight: 500;
  }

  .select__indicator {
    padding: 6px 4px;
  }
}

.select__menu {
  border-radius: $borderRadius !important;
  z-index: 99 !important;
  box-shadow: 0px 0px 5px 0px rgba($black, 0.06) !important;
  border: 1px solid $border;

  .select__menu-list {
    max-height: 214px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .select__option {
    padding: 10px 12px 8px;
    font-size: 13px;
    font-weight: 400 !important;
    color: $grey;
    font-family: "Nunito", sans-serif;

    &:not(:last-child) {
      border-bottom: 1px dashed rgba($border, 0.6);
    }

    &:first-child {
      border-top-left-radius: $borderRadius !important;
      border-top-right-radius: $borderRadius !important;
    }
  }
}

.select__option--is-selected {
  background-color: $white !important;
  color: $primary !important;
}

.select__control--is-focused {
  border: 1px solid $black !important;
}

.Toastify__toast {
  font-family: "Nunito", sans-serif;
}

.statusSent, .statusApproved, .statusDeclined {
  background-color: #fef1e9;
  border: 1px solid #ee7e3d;
  height: 25px;
  padding: 0 12px;
  border-radius: 15px;
  display: inline-block;
  line-height: 23px;
  color: #ee7e3d;
}

.statusApproved {
  background-color: #d9ffea;
  border: 1px solid #3aa36a;
  color: #3aa36a;
}

.statusDeclined {
  background-color: #fde0de;
  border: 1px solid #f76d6e;
  color: #f76d6e;
}

.graphWrap {
  border-radius: $borderRadius;
  background-color: $white;
  padding: 20px 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  margin-bottom: 20px;

  &.pieGraph {
    canvas {
      margin-inline: auto;
    }
  }

  &.lineGraph {
    height: 350px;
  }
}

.pieGraph {
  border-radius: $borderRadius;
  background-color: $white;
  padding: 20px 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  margin-bottom: 20px;
  max-height: 450px;
}

.customTabs {
  background: #f3f3f3;
  // display: inline-flex;
  border-radius: $borderRadius;
  padding: 5px;
  flex-wrap: nowrap;
  overflow-x: auto;

  .nav-item {
    display: flex;
    flex-shrink: 0;

    &:not(:first-child) {
      margin-left: 8px;
    }
  }

  .nav-link {
    padding: 0 20px;
    height: 40px;
    line-height: 41px;
    border-radius: 6px;
    font-size: 14px;
    color: $black;
    border: 0;
    font-weight: 600;
    flex-shrink: 0;
    display: flex;

    &.active {
      background-color: $white;
      color: $primary;
    }
  }
}

.customTabContent {
  margin: 12px 0;
  background-color: $white;
}

.backBtn {
  margin-right: 15px;
  height: 29px;
  width: 29px;
  padding: 0;
  line-height: 29px;
  color: $mediumGrey !important;
  margin-top: -1px;
}

.shopDetailsCarousel {
  margin: 0 auto 20px;

  .carousel-item {
    border-radius: 10px;
    overflow: hidden;
  
    img {
      height: 465px;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
      transition: all .5s;
  
      &:hover {
        transform: scale(1.12);
        transition: all .5s;
      }
    }
  } 
}

.shopInfoList {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: $borderRadius;
  margin-bottom: 20px;

  li {
    border: 0;
    padding: 15px 12px 14px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    &:not(:last-child) {
      border-bottom: 1px dashed $inputBorder;
    }

    i {
      font-size: 12px;
      vertical-align: 0.5px;
    }

    span {
      text-align: right;
      color: $black;
      font-weight: 600;
      margin-left: 15px;
    }
  }
}

.addBrandModal {
  h6 {
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 5px;

    &:not(:first-of-type) {
      margin-top: 15px;
    }
  }
}

.carousel-control-prev, .carousel-control-next {
  width: 35px;

  .carousel-control-prev-icon, .carousel-control-next-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.checkBoxTable {
  .form-check {
    margin-bottom: -2px;
  }
}

.visitCarouselModal {
  .carousel-item {
    img {
      height: 400px;
    }
  }
}

.uploadedSelfie, .merchandisedImage, .productImg {
  border-radius: $borderRadius;
  width: fit-content;
  margin-inline: auto;
  border: 1px solid rgba($border, 0.6);

  img {
    border-radius: $borderRadius;
    width: 250px;
    height: 250px;
    object-fit: cover;
  }
}

.step-indicator {
  display: flex;
  justify-content: space-between;
  margin-block: 10px 25px;
  overflow-x: auto;
}

.step {
  flex: 1;
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid #ccc;
  color: #ccc;
  font-size: 16px;
  text-wrap: nowrap;
  cursor: pointer;
}

.step.active {
  border-bottom: 2px solid $primary;
  color: $primary;
}

.merchandisedImage {
  img {
    width: 300px;
    height: 300px;
  }
}

.stockInHandWrap {
  position: relative;
  border-radius: $borderRadius;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 20px 15px 10px;
  margin-block: 30px;
  display: flex;

  .btn {
    position: absolute;
    width: 22px;
    height: 22px;
    font-size: 14px;
    color: $error !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    background-color: $white !important;
    top: -11px;
    right: -11px;
    padding: 0;
    border-radius: 50%;
    line-height: 21px;
    border: 1px solid rgba($error, 0.7) !important;
    z-index: 99;

    i {
      vertical-align: -0.5px;
    }
  }

  > div {
    width: 100%;

    &+ div {
      min-width: 130px;
      width: 130px;
      text-align: center;
      padding-left: 15px;
    }
  }
}

.text-secondary {
  line-height: 1.6;
  display: inline-block;
}

.settingsWrap {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 20px;
  background: $white;
}

.visitStepWrap {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding-block: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  background: $white;
  margin-inline: 0;
}

.addProductWrap {
  display: flex;
  margin-block: 20px 25px;

  .btn {
    top: -11px;
    right: -1px;
  }

  > div {
    width: 100%;

    &+ div {
      min-width: 230px;
      width: 230px;
      text-align: right;
      padding-left: 15px;
    }
  }
}

.productImg {
  img {
    width: 100px;
    height: 100px;
    cursor: pointer;
  }
}

// note: this project uses reactstrap version -> v9.

// media styles
@media all and (min-width: 1199.98px) {
  .main-content {
    display: flex;

    .app {
      // width: calc(100% - 500px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .sidebarWrapper {
    width: 500px;

    &.sidebarStatic {
      position: static !important;
      visibility: visible !important;
      margin: 18px 0 0px 18px;
    }

    .sidebarInner {
      width: 100%;
      transform: translateX(0);
    }

    &::before {
      display: none !important;
    }
  }
}

@media all and (max-width: 1199.98px) {
  .toggleIcon {
    display: block;
  }

  .sidebarWrapper {
    width: 100%;

    .sidebarInner {
      width: 500px;
    }
  }

  .merchandiseTable {
    tr th {
      min-width: 175px;
    }
  }
}

@media (max-width: 991.99px) {
  .innerHeader {
    h2 {
      font-size: 25px;
    }

    p {
      font-size: 15px !important;
    }
  }

  .sidebarWrapper .sidebarInner {
    width: 300px;
    padding: 15px;
  }

  .projectHeader .container-fluid {
    padding-inline: 10px;
  }

  .filterWrapper {
    // padding-block: 20px 5px;

    .filterIcon {
      position: absolute;
      width: 30px;
      height: 30px;
      top: -16px;
      left: 15px;
      background-color: $white;
      text-align: center;
      line-height: 32px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
      border-radius: 4px;
      font-size: 15px;
    }
  }
}

@media all and (max-width: 767.98px) {
  .navbar-toggler {
    margin-left: auto;
    padding: 0 7px;
    height: 34px;
    box-shadow: none !important;
    position: absolute;
    top: 13.5px;
    right: 15px;

    .navbar-toggler-icon {
      width: 30px;
      height: 30px;
    }
  }

  .navbar-collapse {
    background-color: $white;
    margin-top: 53px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .projectHeader {
    .navbar-nav .nav-item {
      .nav-link {
        padding: 0;
        font-size: 14px;

        &.active::after {
          display: none;
        }
      }
    }

    .logoutBtn {
      padding: 0;
      margin-inline: 5px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;

      span {
        display: initial;
      }

      i {
        display: none;
      }
    }
  }

  .pgTitle h2,
  .sectionTitle {
    font-size: 18px;
  }


  .addBtn, .prevBtn {
    height: 37px;
    line-height: 37px;
    padding: 0 12px;
    font-size: 14px;
  }

  .mobileFilter {
    // display: initial;
    height: 37px;
    line-height: 38px;
    border: 1px solid $border !important;
    background: $white !important;
    color: $grey !important;
    padding: 0 12px;
    margin-left: 10px;
    font-size: 14px;
  }

  .table {
    thead tr th {
      font-size: 13px;
    }

    tbody {
      tr {
        th, td, .btn {
          font-size: 13px;
        }
      }
    }
  }

  .statusSent, .statusApproved, .statusDeclined {
    line-height: 22px;
    height: 23px;
  }

  .pgTitle {
    margin-block: 30px 25px;
  }

  .dashboardCard {
    margin-bottom: 15px;
  }

  .graphWrap {
    padding: 15px 10px;

    &.lineGraph {
      height: unset;
    }
  }

  .table {
    tr th {
      min-width: 175px;
    }
  }

  .checkBoxTable {
    tr th {
      &:first-child {
        min-width: unset;
      }
    }
  }

  .shopDetailsCarousel {
    margin-bottom: 35px;
  }

  .shopInfoList {
    margin-bottom: 15px;
  }

  .table-responsive {
    &:not(:has(.checkBoxTable)) {
      max-height: 563px;
    }
  }

  .step {
    font-size: 13px;
  }
}

@media all and (max-width: 575.98px) {
  .shopInfoWrap {
    margin-bottom: 20px;
  }

  .modal {
    .modal-header {
      padding-bottom: 10px;
    }

    .modal-title {
      font-size: 18px;
    }
  }

  .paginationWrap {
    flex-direction: column;

    .tableCount {
      margin-bottom: 18px;
    }
  }

  .customTabs {
    .nav-item {
      &:not(:first-child) {
        margin-left: 3px;
      }
    }
  
    .nav-link {
      padding: 0 7px;
      font-size: 13px;
      height: 36px;
      line-height: 36px;
    }
  }

  .shopDetailsCarousel, .visitCarouselModal {
    .carousel-item {
      img {
        height: 300px;
      }
    }
  }

  .addProductWrap, .stockInHandWrap {
    flex-direction: column-reverse;
  
    > div {
  
      &+ div {
        min-width: unset;
        width: 100%;
        text-align: center;
        padding-left: 0;
        margin-bottom: 15px;
      }
    }
  }
}